import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/app-download-modal/app-download-modal.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/app-download/download.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/copy-to-clipboard/copy-to-clipboard.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/download-button/download-button.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/fetch-display.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/full-screen-img/full-screen-img.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/select-languages/select-language.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/social-media/social-media-icon.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/time-since/time-since.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/toggle-theme/theme-toggle.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/apps/prayer-schedule-consumer/components/client/token-detail/token-detail.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2550801595/src/crmk-mm-monorepo/node_modules/react-bootstrap/esm/Button.js")